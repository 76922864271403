import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import css from './css/Header.module.scss';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function Header({ musiclist, status }) {

  const location = useLocation();

  const [Active, setActive] = useState(false);
  const [Musiclist, setMusicList] = useState([]);
  const [who, setWho] = useState();

  // 動態導航欄
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      if (scrollPosition > 100) {
        setActive(true);
      } else {
        setActive(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };

  })

  // 即時更新 Musiclist
  useEffect(() => {
    setMusicList(musiclist);
  }, [musiclist]);

  useEffect(() => {
    setWho(status);
  }, [status]);

  return (
    <header className={`${css.Header} ${Active && css.active}`}>
      <div className={css.nav}>
        <div className={css.logo}>
          <div>
            <FontAwesomeIcon icon={faBars} />
          </div>
          <a href="/" title="Raxytw 小破站">Raxytw 小破站</a>
        </div>
        <div className={css.hamburger}>
          <ul>
            {location.pathname === '/' && (
              <>
                <li>
                  <p>歡迎來到 Raxytw 小破站</p>
                </li>
                <li>
                  <a href="https://pocketplanet.web.app/" target="_blank" title='點我可前往'>我做了一個 ⟪ 專題網站 ⟫ 可以去看看</a>
                </li>
              </>
            )}
            {location.pathname.startsWith('/music') &&
              <>
                {who !== undefined ? (
                  <>
                    <li>
                      <p>當前撥放 ⟪ {Musiclist[who].track.name} ⟫</p>
                    </li>
                    <li>
                      <p>正在撥放音樂 🎵</p>
                    </li>
                  </>
                )
                  : (
                    <>
                      <li>
                        <p>在這裡你可以聽音樂</p>
                      </li>
                      <li>
                        <p>目前收入 {Musiclist ? Musiclist.length : '0'} 首</p>
                      </li>
                    </>
                  )}
              </>
            }
          </ul>
        </div>
      </div>
    </header>
  )
};