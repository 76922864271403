import css from './css/DataDownload.module.scss';

export default function DataDownload() {
  return (
    <main className={css.Down}>
      <div className={css.all}>
        <div className={css.list}>
          <ul>
            {

            }
          </ul>
        </div>
      </div>
    </main>
  )
}