import { useEffect, useState } from 'react';
import css from './css/CodeWrite.module.scss';

export default function CodeWrite({ color }) {

  const [styles, Setstyles] = useState("drak");

  useEffect(() => {
    if (color !== undefined) {
      Setstyles(color);
    }
  }, [color]);

  return (
    <div className={css.container}>
      <div className={`${css.codewrite} ${styles === "light" ? css.light : ""}`}>
        <p>開發中請稍後...</p>
      </div>
      <p className={css.floating_text}>開發中請稍後...</p>
    </div>
  )
};
