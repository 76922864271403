import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Home from './pages/Home';
import About from './pages/About';
import Music from './pages/Music';
import Games from './pages/Games';
import Anime from './pages/Anime';
import Lift from './pages/Lift';
import DataDownload from './pages/DataDownload';
import Notfound from './pages/Notfound';
import Header from './widgets/Header';
import Footer from './widgets/Footer';
import Particle from './widgets/Paricle';

function App() {

  const location = useLocation();
  const [path, Setpath] = useState("/");
  const [musiclist, setMusicList] = useState([]);
  const [play, setPlay] = useState();

  // 獲取當前頁面 path
  useEffect(() => {
    Setpath(location.pathname);
  }, [location.pathname]);

  // 獲取 Spotify 音樂列表
  useEffect(() => {

    // Spotify APIs
    const client_id = "68c2ac2200b743549c34a400dee86995";
    const client_secret = "94d4c57cf0d240c08df08de7e2b70f51";

    const requestBody = new URLSearchParams();
    requestBody.append('grant_type', 'client_credentials');
    requestBody.append('client_id', client_id);
    requestBody.append('client_secret', client_secret);

    function request() {
      fetch('https://accounts.spotify.com/api/token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: requestBody
      })
        .then(response => response.json())
        .then(data => {
          const Token = data.access_token;

          fetch('https://api.spotify.com/v1/playlists/6LF2p89XaHHQQRAWIqJBQa/tracks', {
            headers: {
              'Authorization': 'Bearer ' + Token
            }
          })
            .then(response => response.json())
            .then(data => {
              const items = data.items;
              setMusicList(items);
            })
            .catch(error => {
              console.error('Error:', error);
            });
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }

    request()

    setInterval(request, 60000)

  }, []);

  // 更新 title
  switch (location.pathname) {
    case "" | "/":
      document.title = "Raxytw 小破站 | 主頁";
      break;
    case "/list":
      document.title = "Raxytw 小破站 | 列表";
      break;
    case "/music":
      document.title = "Raxytw 小破站 | 我的音樂🎧";
      break;
    case "/about":
      document.title = "Raxytw 小破站 | 關於網站📜";
      break;
    case "/games":
      document.title = "Raxytw 小破站 | 遊戲🎮";
      break;
    case "/anime":
      document.title = "Raxytw 小破站 | 影視🕶️";
      break;
    case "/life":
      document.title = "Raxytw 小破站 | 生活🏠";
      break;
    case "/notfound":
      document.title = "Raxytw 小破站 | 迷失🚀";
      break;
    case "/setting":
      document.title = "Raxytw 小破站 | 設定⚙️";
      break;
    case "/download":
      document.title = "Raxytw 小破站 | 資料📁";
      break;
  }

  // 判定頁面隱藏
  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'hidden') {
      document.title = '不要走!再看看嘛!';
    } else {
      document.title = '歡迎肥來 (^∇^*)♭';
    }
  });

  const GetPlayStatus = (callback) => {
    setPlay(callback);
  };

  return (
    <>
      <Particle />
      {location.pathname !== '/notfound' &&
        <Header musiclist={musiclist} status={play} />
      }
      <Routes>
        <Route path="/" element={<Home musiclist={musiclist} />} />
        <Route path="/list" element={<Home musiclist={musiclist} />} />
        <Route path="/music/:number?" element={<Music list={musiclist} playstatus={GetPlayStatus} />} />
        <Route path="/about" element={<About />} />
        <Route path="/games" element={<Games />} />
        <Route path="/anime" element={<Anime />} />
        <Route path="/life" element={<Lift />} />
        <Route path="/download" element={<DataDownload />} />
        <Route path="*" element={<Navigate to="/notfound" />} />
        <Route path="/notfound" element={<Notfound />} />
      </Routes>
      {location.pathname !== '/notfound' &&
        <Footer path={path} />
      }
      {/*

                        ___====-_  _-====___
                  _--^^^     //      \\     ^^^--_
                _-^          // (    ) \\          ^-_
              -            //  |\^^/|  \\            -
            _/            //   (@::@)   \\            \_
            /             ((     \\//     ))             \
          -               \\    (oo)    //               -
          -                 \\  / VV \  //                 -
        -                   \\/      \//                   -
        _ /|          /\      (   /\   )      /\          |\ _
        |/ | /\ /\ /\/  \ /\  \  |  |  /  /\ /  \/\ /\ /\ | \|
        `  |/  V  V  `   V  \ \| |  | |/ /  V   '  V  V  \|  '
          `   `  `      `   / | |  | | \   '      '  '   '
                            (  | |  | |  )
                          __\ | |  | | /__
                          (vvv(VVV)(VVV)vvv)
                          神獸保佑，程式碼沒Bug!
      */}
    </>
  );
}

export default App;
