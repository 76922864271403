import { useEffect, useState } from 'react';
import css from './css/Footer.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreativeCommons, faCreativeCommonsBy, faCreativeCommonsNcEu, faCreativeCommonsSa } from '@fortawesome/free-brands-svg-icons';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

export default function Footer({ path }) {

  const [YData, setYData] = useState();
  const [nowpath, setPath] = useState("/music");

  // 取得年
  useEffect(() => {
    setYData(new Date().getFullYear());
  }, []);

  // 更新當前路徑
  useEffect(() => {
    setPath(path);
  }, [path]);

  // 檢查是否是 /music 及其子頁面
  const isMusicPage = nowpath.startsWith('/music');

  return (
    <footer className={css.Footer}>
      {!isMusicPage &&
        <div className={css.info}></div>
      }
      <div className={css.cc}>
        <div className={css.sign}>
          <span title={`今年 ${YData} 啦`}> © 2022 - {YData ? YData : '2022'} By <a href="https://github.com/Raxytw" target="_blank" title="前往 Github">Raxytw 小破站</a></span>
        </div>
        <div className={css.allow}>
          <span>授權許可</span>
          <ul>
            <FontAwesomeIcon icon={faCreativeCommons} />
            <FontAwesomeIcon icon={faCreativeCommonsBy} />
            <FontAwesomeIcon icon={faCreativeCommonsNcEu} />
            <FontAwesomeIcon icon={faCreativeCommonsSa} />
          </ul>
        </div>
        <div className={css.menu}>
          <ul>
            <a href="/download" title="網頁資料下載處">
              <span>資料 </span>
              <FontAwesomeIcon icon={faDownload} />
            </a>
            <a href="/about" title="關於我的網站">
              <span>關於</span>
            </a>
          </ul>
        </div>
      </div>
    </ footer>
  )
}