import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import css from './css/Music.module.scss';

export default function Music({ list, playstatus }) {

  const { number } = useParams();
  const [Musiclist, setMusicList] = useState([]);
  const [currentPlaying, setCurrentPlaying] = useState(0);
  const [iframeSrc, setIframeSrc] = useState();
  const [status, setStatus] = useState(false);

  // 即時更新 Musiclist
  useEffect(() => {
    setMusicList(list);
  }, [list]);

  // 更新音樂
  const handleClick = useCallback((index, list) => {
    setCurrentPlaying(index);
    playstatus(index);
    setIframeSrc(`https://open.spotify.com/embed/track/${list.track.id}`);
    const songName = encodeURIComponent(list.track.name);
    window.history.replaceState({}, '', `/music/${songName}`);
  }, []);

  // 監聽滾動事件
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      if (scrollPosition >= documentHeight * 0.99) {
        setStatus(true);
      } else {
        setStatus(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (number >= 0 && number < Musiclist.length) {
      setCurrentPlaying(parseInt(number, 10));
      playstatus(number);
      setIframeSrc(`https://open.spotify.com/embed/track/${Musiclist[number].track.id}`);

      const songName = encodeURIComponent(Musiclist[number].track.name);
      // 使用 pushState 更新 URL，但不重新載入頁面
      window.history.replaceState({}, '', `/music/${songName}`);
    }
  }, [number, Musiclist]);

  return (
    <main className={css.Music}>
      <div className={css.list}>
        <ul>
          {Musiclist && Musiclist.length > 0 ? (
            Musiclist.map((item, index) => (
              <li
                key={index}
                className={currentPlaying === index ? css.play : ''}
                onClick={() => handleClick(index, item)}
              >
                <div title={`${item.track.name} - ${item.track.album.artists[0].name}`}>
                  <div className={css.img}>
                    <img src={item.track.album.images[2].url} alt={item.track.name} />
                  </div>
                  <div className={css.name}>
                    <p>{item.track.name}</p>
                  </div>
                </div>
              </li>
            ))
          ) : (
            <p>Error, Please reWebsite</p>
          )}
        </ul>
      </div>
      {iframeSrc &&
        <iframe
          className={`${css.cdplayer} ${status ? css.top : ""}`}
          src={iframeSrc}
          width="30%"
          height="152"
          frameBorder="0"
          allowtransparency="true"
          allow="encrypted-media"
        ></iframe>
      }
    </main >
  )
}
