import Typed from 'typed.js';
import css from '../css/Home.module.scss';
import Font from '../../assets/Typed/.json';
import { useEffect, useRef } from 'react';

export default function CreateTyped() {

  const el = useRef(null);

  useEffect(() => {

    var rag = Math.floor(Math.random() * Font.style.length);

    const TypedArr = Font.style[rag].concat(Font.defu);

    const typed = new Typed(el.current, {
      strings: TypedArr,
      typeSpeed: Font.typeSpeed,
      backSpeed: Font.backSpeed,
      loop: Font.loop
    });

  }, [])

  return (
    <div className={css.text}>
      <span ref={el}></span>
    </div>
  )
}