import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyD6oYKXVfUHctK9lpcoObspZxxBmjhijc0",
  authDomain: "raxytw-project.firebaseapp.com",
  projectId: "raxytw-project",
  storageBucket: "raxytw-project.appspot.com",
  messagingSenderId: "480133789366",
  appId: "1:480133789366:web:4e5bd6fad41f90373dd817",
  measurementId: "G-HM1YEB2X7Q"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
