import css from './css/Notfound.module.scss';
import astronautImg from '../assets/Notfound/astronaut.png';

export default function Notfound() {
  return (
    <main className={css.Notfound}>
      <div className={css.page}>
        <h1>宇宙裡沒有這一頁</h1>
        <p>你可能做了什麼，所以來到了這裡</p>
        <p className={css.info}>
          你可以通過下方的按鈕返回主頁
        </p>
        <a href="/" className={css.btn}>回主頁</a>
      </div>
      <div className={css.astronaut}>
        <img src={astronautImg} />
      </div>
    </main>
  )
}