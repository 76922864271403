import css from './css/Particle.module.scss';
import { useEffect, useRef } from 'react';

export default function Particle() {

  const ParRef = useRef(null);

  // useEffect(() => {

  //   const canvas = ParRef.current;
  //   const ctx = canvas.getContext('2d');

  //   canvas.width = window.innerWidth;
  //   canvas.height = window.innerHeight;

  //   const particles = [];

  //   class Particlefun {
  //     constructor() {
  //       this.x = Math.random() * (canvas.width + 400) - 200;
  //       this.y = canvas.height + Math.random() * 200;
  //       this.radius = Math.random() * 2.5;
  //       this.speedX = Math.random() * 1;
  //       this.speedY = -Math.random() * 1;

  //       var styleindex = Math.floor(Math.random() * 100);
  //       this.color = (styleindex > 50) ? '#a1a7c7' : '#f2b94b';
  //     }
  //     update() {
  //       this.x += this.speedX;
  //       this.y += this.speedY;
  //       if (this.x > canvas.width || this.y < 0) {
  //         this.x = Math.random() * (canvas.width + 400) - 200;
  //         this.y = canvas.height + Math.random() * 200;
  //       }
  //     }
  //     draw() {
  //       ctx.beginPath();
  //       ctx.fillStyle = this.color;
  //       ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2);
  //       ctx.fill();
  //     }
  //   }

  //   function createParticles() {
  //     for (let i = 0; i < 300; i++) {
  //       particles.push(new Particlefun());
  //     }
  //   }

  //   function animate() {
  //     requestAnimationFrame(animate);
  //     ctx.clearRect(0, 0, canvas.width, canvas.height);

  //     for (let i = 0; i < particles.length; i++) {
  //       particles[i].update();

  //       var styleindex = Math.floor(Math.random() * 100);
  //       if (styleindex > 50) {
  //         ctx.fillStyle = '#a1a7c7';
  //       } else {
  //         ctx.fillStyle = '#f2b94b';
  //       }

  //       particles[i].draw();
  //     };
  //   };

  //   createParticles();
  //   animate();

  // });

  useEffect(() => {
    const canvas = ParRef.current;
    const ctx = canvas.getContext('2d');

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const particles = [];

    class Particlefun {
      constructor() {
        this.x = Math.random() * (canvas.width + 400) - 200;
        this.y = canvas.height + Math.random() * 200;
        this.radius = Math.random() * 2.5;
        this.speedX = Math.random() * 1;
        this.speedY = -Math.random() * 1;

        var styleindex = Math.floor(Math.random() * 100);
        this.color = (styleindex > 50) ? '#a1a7c7' : '#f2b94b';
      }
      update() {
        this.x += this.speedX;
        this.y += this.speedY;
        if (this.x > canvas.width || this.y < 0) {
          this.x = Math.random() * (canvas.width + 400) - 200;
          this.y = canvas.height + Math.random() * 200;
        }
      }
      draw() {
        ctx.beginPath();
        ctx.fillStyle = this.color;
        ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2);
        ctx.fill();
      }
    }

    function createParticles() {
      for (let i = 0; i < 300; i++) {
        particles.push(new Particlefun());
      }
    }

    function animate() {
      requestAnimationFrame(animate);
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      for (let i = 0; i < particles.length; i++) {
        particles[i].update();

        var styleindex = Math.floor(Math.random() * 100);
        if (styleindex > 50) {
          ctx.fillStyle = '#a1a7c7';
        } else {
          ctx.fillStyle = '#f2b94b';
        }

        particles[i].draw();
      }
    }

    createParticles();
    animate();

    // Optional: Clean up function
    return () => {
      cancelAnimationFrame(animate);
    };
  }, [window.innerWidth]); // Empty dependency array


  return (
    <canvas ref={ParRef} className={css.canvas} />
  )
}