import {
  faAngleDown,
  faArrowRight,
  faCalendarWeek,
  faClipboard,
  faHeadphones,
  faImage,
  faMusic,
  faPaperclip,
  faRepeat,
  faThumbtack
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import { faGithub, faSlack, } from '@fortawesome/free-brands-svg-icons';
import css from './css/Home.module.scss';
import fox from '../assets/Home/widgets/author.gif';
import happy from '../assets/Home/widgets/look.webp';
import steamcard from '../assets/Home/widgets/steamcard.webp';
import CreateTyped from './Typing/CreateTyped';
import { db } from '../base/Firebase';
import { collection, onSnapshot } from 'firebase/firestore';
import CodeWrite from '../widgets/CodeWrite';

export default function Home({ musiclist }) {

  const [Face, setFace] = useState("https://firebasestorage.googleapis.com/v0/b/raxytw-project.appspot.com/o/faces%2Femoji1.gif?alt=media&token=a880c17b-32e1-4216-855e-91cc870977e0");
  const [Anime, setAnime] = useState([{ state: true }, { state: false }, { state: false }, { state: true }]);
  const [Userip, setUserip] = useState("0.0.0.0");
  const [Usercountry, setUsercountry] = useState("Taiwan");
  const [MusicList, setMusicList] = useState([]);
  const [AnimeList, setAnimeList] = useState([]);
  const [FaceList, setFaceList] = useState([]);
  const [Svgstate, setSvgstate] = useState(false);

  // Anim ReAnime Sub
  const Re = () => {
    const svgRef = useRef(null);

    const handleClick = () => {
      setSvgstate(true);

      setTimeout(() => {
        setSvgstate(false);
      }, 1000);
    };

    return (
      <div className={css.sign}>
        <FontAwesomeIcon className={Svgstate ? css.animated : ''} ref={svgRef} icon={faRepeat} title="點我刷新🪄" onClick={handleClick} />
      </div>
    );
  };

  // Get Data
  useEffect(() => {

    const sec = 5;
    const cities = [];

    const FetchData = () => {
      const unsubscrible = onSnapshot(collection(db, "Home"), (q) => {
        q.forEach((doc) => {
          cities[doc.id] = doc.data();
        })
        setFaceList(cities.Face.faces);
        setAnimeList(cities.Anime);
      })
    }

    FetchData();

    const intervalId = setInterval(FetchData, sec * 1000);
    return () => clearInterval(intervalId);

  }, [])

  // Get ip 和 country
  useEffect(() => {

    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => {
        setUserip(data.ip);

        fetch(`https://ipapi.co/${data.ip}/json/`)
          .then(function (response) {
            response.json().then(jsonData => {
              setUsercountry(jsonData.country_name);
            });
          })
          .catch(function (error) {
            console.log(error)
          });
      });

  }, [])

  // Random face  
  useEffect(() => {

    if (FaceList.length > 0) {
      const randomIndex = Math.floor(Math.random() * FaceList.length);
      setFace(FaceList[randomIndex]);
    }

  }, [FaceList.length]);

  useEffect(() => {
    setMusicList(musiclist);
  }, [musiclist]);

  return (
    <main className={css.Home}>
      <div className={css.bg}></div>
      <div className={css.info}>
        <div className={css.title}>
          <span>Raxytw 小破站</span>
          <CreateTyped />
        </div>
        <div className={css.down}>
          <a href="#viewr">
            <FontAwesomeIcon icon={faAngleDown} />
          </a>
        </div>
      </div>
      <div id="viewr" className={css.viewr}>
        <div className={css.viewbox}>
          <div className={css.label}>
            <div className={css.sign}>
              <FontAwesomeIcon icon={faSlack} />
            </div>
            <div className={css.content}>
              <span>名言 : 生命因何而沉睡？因为我们总有一天会醒来</span>
            </div>
          </div>
          <div className={css.recmd}>
            <div className={css.poo}>
              <div>
                <span>我的技術客棧</span>
                <p>更多內容關注 Github</p>
                <a href='/list'>
                  <span>隨便看看</span>
                  <div className={css.box}>
                    <FontAwesomeIcon icon={faArrowRight} />
                  </div>
                </a>
              </div>
              <ul>
                <a href="/music" title="好聽的音樂不來點?">
                  <span>我的音樂</span>
                  <FontAwesomeIcon icon={faHeadphones} />
                </a>
                <a href="/post" title="來留個言唄!">
                  <span>布告欄</span>
                  <FontAwesomeIcon icon={faClipboard} />
                </a>
                <a href="/life" title="紀錄每一天美好生活!?">
                  <span>我的生活</span>
                  <FontAwesomeIcon icon={faImage} />
                </a>
              </ul>
            </div>
            <div className={css.opp} title="關於我的網站與歷史">
              <div>
                <a href="/about">
                  <span>關於我的網站</span>
                  <div>
                    <FontAwesomeIcon icon={faArrowRight} />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={css.main}>
          <div className={css.content}>
            <div className={css.labal}>
              <Re />
              <div className={css.content}>
                <span>動漫與動畫檣</span>
              </div>
              <div className={css.nums}>
                <span title={`目前收錄 ${AnimeList.length > 0 ? AnimeList.length : '0'} 個`}>
                  {AnimeList.length > 0 ? AnimeList.length : '0'}
                </span>
              </div>
            </div>
            {/* <div className={css.viewAnime}>
              <ul>
                {Anime.map((index, key) => (
                  <li key={key}>
                    <div className={`${css.state} ${index.state === false ? css.end : ''} ${index.state === true ? css.live : ''}`}>
                      <span>
                        {index.state === false ? '以完結' : '穩定更新中'}
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            </div> */}
            <CodeWrite />
          </div>
          <div className={css.widget}>
            <div className={css.author}>
              <div className={css.minder}>
                <span>集中精神，攻克難關</span>
              </div>
              <div className={css.icon}>
                <div className={css.hide}>
                  <img src={fox} alt="author" title="Raxytw" />
                </div>
                <div className={css.face}>
                  {FaceList.length > 0 &&
                    <img src={Face} alt="faces" />
                  }
                </div>
              </div>
              <div className={css.name}>
                <div>
                  <span>Raxytw</span>
                  <p>分享生活與技術</p>
                </div>
                <ul>
                  <a href="https://github.com/Raxytw" title="My Github">
                    <FontAwesomeIcon icon={faGithub} />
                  </a>
                  <a href="mailto:asiatw99@gmail.com" title="Email for me">
                    <FontAwesomeIcon icon={faPaperclip} />
                  </a>
                </ul>
              </div>
              <div className={css.codetext}>
                <span></span>
              </div>
            </div>
            <div className={css.card}>
              <div className={css.title}>
                <FontAwesomeIcon icon={faThumbtack} />
                <span>小報帖</span>
              </div>
              <div className={css.content}>
                <p>歡迎來自 <i>{Usercountry}</i> 的仔仔</p>
                <br />
                <p>🌟</p>
                <br />
                <p>我在這頭，<i>{Usercountry}</i> 在那頭</p>
                <p>你的 IP 是 </p>
                <p><i>{Userip}</i></p>
              </div>
              <div className={css.emoji}>
                <img src={happy} alt="image_happy" />
              </div>
            </div>
            <div className={css.steam}>
              <div>
                <span title='我的 Steam 😺'>Steam Card</span>
              </div>
              <a target='_blank' href="https://steamcommunity.com/id/oiklM/" title="點我前往 ➡️">
                <img src={steamcard} alt="Steam-Card" />
              </a>
            </div>
            <div className={css.music}>
              <div className={css.title}>
                <FontAwesomeIcon icon={faMusic} />
                <span>音樂天地</span>
              </div>
              <div className={css.list}>
                <ul>
                  {MusicList.map((index, key) => (
                    <a href={`/music/${key}`} key={key} title={`${index.track.name} - ${index.track.album.artists[0].name}`}>
                      <div className={css.img}>
                        <img src={index.track.album.images[2].url} alt={index.track.name} />
                      </div>
                      <div className={css.name}>
                        <p>{index.track.name}</p>
                      </div>
                    </a>
                  ))}
                  {!MusicList &&
                    <li>
                      <span>🔄 加載中 🔄</span>
                    </li>
                  }
                </ul>
              </div>
              <div className={css.solY}>
                <span>滑動還有更多</span>
                <p>© 資料來自 Spotify</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
};